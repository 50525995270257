import React from "react"
import WelcomeMessage from "./UI/WelcomeMessage"
import UpToDateMessage from "./UI/UpToDateMessage"

// todo: ?
const Content = ({ notifications }) => {
  return (
    <>
      <div className="d-none d-lg-block">
        <WelcomeMessage />        
      </div>
      {/*  */}
      {!notifications.length && <UpToDateMessage />}
    </>
  )
}

export default Content
