// extracted by mini-css-extract-plugin
export var bgGrey100 = "LoginView-module--bg-grey-100--15c58";
export var bgGrey150 = "LoginView-module--bg-grey-150--5c16a";
export var bgGrey200 = "LoginView-module--bg-grey-200--033e7";
export var bgGrey300 = "LoginView-module--bg-grey-300--7dd42";
export var bgGrey400 = "LoginView-module--bg-grey-400--dcf20";
export var bgGrey500 = "LoginView-module--bg-grey-500--fc83b";
export var bgGrey600 = "LoginView-module--bg-grey-600--8bae2";
export var bgGrey700 = "LoginView-module--bg-grey-700--bb4a6";
export var bgGrey800 = "LoginView-module--bg-grey-800--c19c2";
export var bgGrey900 = "LoginView-module--bg-grey-900--c4b37";
export var formCls = "LoginView-module--formCls--cdb45";
export var textGrey100 = "LoginView-module--text-grey-100--da9fc";
export var textGrey150 = "LoginView-module--text-grey-150--7e585";
export var textGrey200 = "LoginView-module--text-grey-200--5e82f";
export var textGrey300 = "LoginView-module--text-grey-300--9d787";
export var textGrey400 = "LoginView-module--text-grey-400--194b8";
export var textGrey500 = "LoginView-module--text-grey-500--e1ce7";
export var textGrey600 = "LoginView-module--text-grey-600--55eb4";
export var textGrey700 = "LoginView-module--text-grey-700--15f82";
export var textGrey800 = "LoginView-module--text-grey-800--9a9af";
export var textGrey900 = "LoginView-module--text-grey-900--3af85";