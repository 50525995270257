import React from "react"
import LinkButton from "../../UI/Common/LinkButton"

const UpToDateMessage = () => {
  return (
    <div className={`d-flex flex-column align-items-center pt-3`}>
      <h3 className="pb-3">You are up to date with everything</h3>
      <p className="font-weight-light">
        Here you will find all the updates regarding your orders, returns and
        booked services
      </p>
      <LinkButton title="Continue Shopping" link="/home" />
    </div>
  )
}

export default UpToDateMessage
