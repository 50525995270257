import { graphql } from "gatsby"
import React from "react"
import ClientPanel from './ClientPanel'




// Think of some data from WP?

export const ACFClientPanel = () => {
  return (
    <ClientPanel />    
  )
}