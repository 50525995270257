// extracted by mini-css-extract-plugin
export var backgroundCls = "Sidebar-module--backgroundCls--408a4";
export var bgGrey100 = "Sidebar-module--bg-grey-100--0b7de";
export var bgGrey150 = "Sidebar-module--bg-grey-150--f7729";
export var bgGrey200 = "Sidebar-module--bg-grey-200--cccfc";
export var bgGrey300 = "Sidebar-module--bg-grey-300--58923";
export var bgGrey400 = "Sidebar-module--bg-grey-400--dc8df";
export var bgGrey500 = "Sidebar-module--bg-grey-500--9fa49";
export var bgGrey600 = "Sidebar-module--bg-grey-600--f63d9";
export var bgGrey700 = "Sidebar-module--bg-grey-700--ce466";
export var bgGrey800 = "Sidebar-module--bg-grey-800--d91d8";
export var bgGrey900 = "Sidebar-module--bg-grey-900--c57d5";
export var navTitleCls = "Sidebar-module--navTitleCls--2cd63";
export var navigationWrapper = "Sidebar-module--navigationWrapper--5478c";
export var textGrey100 = "Sidebar-module--text-grey-100--4b4cb";
export var textGrey150 = "Sidebar-module--text-grey-150--e8ef0";
export var textGrey200 = "Sidebar-module--text-grey-200--a5261";
export var textGrey300 = "Sidebar-module--text-grey-300--1b6d7";
export var textGrey400 = "Sidebar-module--text-grey-400--8adcf";
export var textGrey500 = "Sidebar-module--text-grey-500--47fc1";
export var textGrey600 = "Sidebar-module--text-grey-600--83892";
export var textGrey700 = "Sidebar-module--text-grey-700--7ebe0";
export var textGrey800 = "Sidebar-module--text-grey-800--37872";
export var textGrey900 = "Sidebar-module--text-grey-900--3e1d3";