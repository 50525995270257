import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import * as PropTypes from "prop-types"

import { Form, Button } from "react-bootstrap"
import { formCls } from "./LoginView.module.scss"
import { loginOperations, loginSelectors } from "../../../state/auth"
import { createStructuredSelector } from "reselect"
import PasswordInputForm from "../../UI/LoginForm/PasswordInputForm"

const LoginView = ({  isLoggingIn,
  signInUser,
  signInUserReset,
  isError,
  isLoggedIn,}) => {
  useEffect(() => {
    //check if user is already logged in
    if(isLoggedIn){
      navigate("/")
    } else {
      signInUserReset()
    }
  }, [])

  useEffect(()=> {
    //navigate to home page if user has been logged in successfully
    if(isLoggedIn) {
      navigate("/")
    }
  },[isLoggedIn])


  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()

    signInUser(form.elements.email.value, form.elements.password.value)
  }

  return (
    <div className={`d-flex flex-column align-items-center`}>
      <h3 className="pb-3 text-center">Please login to your account</h3>
      <Form
        method="post"
        className={formCls}
        onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control autoFocus type="email" name="email" />
        </Form.Group>
        <PasswordInputForm
          label="Password"
          disabled={isLoggingIn}
          placeholder="Enter password"
          required={true}
          className="mb-3"
          controlId="password"
          autocomplete="current-password"
        />
        <Button block size="lg" type="submit" disabled={isLoggingIn}>
          Login
        </Button>
      </Form>



    </div>
  )
}
LoginView.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  signInUser: PropTypes.func.isRequired,
  signInUserReset: PropTypes.func.isRequired,
}

LoginView.defaultProps = {}

const mapStateToProps = createStructuredSelector({
  isLoggingIn: loginSelectors.isLoggingIn,
  isError: loginSelectors.isError,
  isLoggedIn: loginSelectors.isLoggedIn,
})

const mapDispatchToProps = {
  signInUser: loginOperations.signInUser,
  signInUserReset: loginOperations.signInUserReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
