import React from "react"
import { connect } from "react-redux"

// UI
import { Col } from "react-bootstrap"
import Sidebar from "../Sidebar"
import Content from "../Content"



const PanelView = () => {
  const notifications = []
  return (
    <>
      <Col lg={3} className="p-0">
        <Sidebar />
      </Col>
      <Col lg={9} className="p-3 p-lg-0">
        <Content notifications={notifications} />
      </Col>
    </>
  )
}

const mapStateToProps = state => ({
  mapStateToProps: 'testMapStateToPropsPanelView' ,
})

const mapDispatchToProps = {
  mapDispatchToProps: 'tesmapDispatchToPropsPanelView' ,
}

export default connect(mapStateToProps,mapDispatchToProps)(PanelView)