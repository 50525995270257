import React from "react"
import { backgroundCls } from "../Sidebar.module.scss"
import { Col } from "react-bootstrap"

const WelcomeMessage = () => {
  return (
    <div className={`${backgroundCls} d-flex flex-column flex-lg-row`}>
      <Col lg={3}>
        <h3 className="pb-3">Your Account</h3>
      </Col>
      <Col>
        <p className="font-weight-light">
          Welcome to your Central Bikes profile where you can manage your
          orders, returns and account settings
        </p>
      </Col>
    </div>
  )
}

export default WelcomeMessage
