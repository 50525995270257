import React from "react"
import { Nav } from "react-bootstrap"
import { v4 } from "uuid"

import WelcomeMessage from "./UI/WelcomeMessage"

import { navigationWrapper, navTitleCls } from "./Sidebar.module.scss"


//todo: routing?

const Sidebar = props => {
  const sideMenuItems = {
    myAccount: ["Orders & Invoices", "Services", "Addresses"],
    settings: ["Profile", "Newsletter & Notification", "Help & Contact"],
  }

  return (
    <div className={navigationWrapper}>
      <div className="d-block d-lg-none">
        <WelcomeMessage />
      </div>
      <Nav className="flex-column">
        {/* My Account  */}
        <Nav.Item className={navTitleCls}>
          <b>My Account</b>
        </Nav.Item>
        {sideMenuItems?.myAccount.map(menuItem => (
          <Nav.Item key={v4()}>
            <Nav.Link className="py-3">{menuItem}</Nav.Link>
          </Nav.Item>
        ))}
        {/* Settings  */}
        <Nav.Item className={navTitleCls}>
          <b>Settings</b>
        </Nav.Item>
        {sideMenuItems?.settings.map(menuItem => (
          <Nav.Item key={v4()}>
            <Nav.Link className="py-3">{menuItem}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  )
}
export default Sidebar
