import React from "react"
import { connect } from "react-redux"
import { Container, Row} from "react-bootstrap"
import { clientWrapper } from "./ClientPanel.module.scss"
import PanelView from "./View/PanelView"
import LoginView from "./View/LoginView"
// import LoginForm from "../UI/LoginForm/LoginForm"
import {
  loginActions,
  loginOperations,
  loginSelectors,
  loginTypes,
} from "../../state/auth/index"

// import { ,  } from "../../state/"
const ClientPanel = ({}) => {
  const isLoggedIn = false
  
  
  
  // Probably this is not going to happen right here
  return (
    <div className={clientWrapper}>
      <Container fluid>
        <Row className=" d-flex justify-content-center">
          {isLoggedIn ? <PanelView /> : <LoginView/>}
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({
  getToken: loginOperations.getToken,
  singInUser: loginOperations.signInUser(state),
  signOutUser: loginOperations.signOutUser(state),
  signInUserReset: loginOperations.signInUserReset(state),
})

const mapDispatchToProps = {
  validateTokenUserRequest: loginActions.validateTokenUserRequest,
  validateTokenUserSuccess: loginActions.validateTokenUserSuccess,
  validateTokenUserFailure: loginActions.validateTokenUserFailure,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPanel)
