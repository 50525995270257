// extracted by mini-css-extract-plugin
export var bgGrey100 = "ClientPanel-module--bg-grey-100--bca21";
export var bgGrey150 = "ClientPanel-module--bg-grey-150--e1eb1";
export var bgGrey200 = "ClientPanel-module--bg-grey-200--e2c0c";
export var bgGrey300 = "ClientPanel-module--bg-grey-300--ce1c7";
export var bgGrey400 = "ClientPanel-module--bg-grey-400--025a8";
export var bgGrey500 = "ClientPanel-module--bg-grey-500--cd55b";
export var bgGrey600 = "ClientPanel-module--bg-grey-600--6876d";
export var bgGrey700 = "ClientPanel-module--bg-grey-700--c8e6e";
export var bgGrey800 = "ClientPanel-module--bg-grey-800--e4836";
export var bgGrey900 = "ClientPanel-module--bg-grey-900--5a554";
export var clientWrapper = "ClientPanel-module--clientWrapper--b6972";
export var textGrey100 = "ClientPanel-module--text-grey-100--2573d";
export var textGrey150 = "ClientPanel-module--text-grey-150--e3430";
export var textGrey200 = "ClientPanel-module--text-grey-200--fa598";
export var textGrey300 = "ClientPanel-module--text-grey-300--81d41";
export var textGrey400 = "ClientPanel-module--text-grey-400--15ea3";
export var textGrey500 = "ClientPanel-module--text-grey-500--e411c";
export var textGrey600 = "ClientPanel-module--text-grey-600--859ce";
export var textGrey700 = "ClientPanel-module--text-grey-700--48086";
export var textGrey800 = "ClientPanel-module--text-grey-800--0f01c";
export var textGrey900 = "ClientPanel-module--text-grey-900--27c1a";